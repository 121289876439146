import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Layout, Menu, Button, theme } from "antd";
import { useTonWallet, useTonAddress } from '@tonconnect/ui-react';
import { TonConnectButton } from '@tonconnect/ui-react';



import { HomeOutlined, UsergroupAddOutlined, TrophyOutlined } from '@ant-design/icons';


const { Header, Sider, Content } = Layout;

const getIsMobile = () => window.innerWidth <= 1280;

const TemplateLayout = ({ children }) => {

  const wallet = useTonWallet();
  const friendlyAddress = useTonAddress();

  const [isMobile, setIsMobile] = useState(getIsMobile());

  const [collapsed, setCollapsed] = useState(isMobile ? true : false);

  const [isShowMenu, setIsShowMenu] = useState(false);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const location = useLocation();

  const [activeMenuKey, setActiveMenuKey] = useState(location?.pathname);

  
  const [tonBalance, setTonBalance] = useState(0);

  useEffect(() => {
    setActiveMenuKey(location?.pathname);
  }, [location]);

  useEffect(() => {
    
  }, [wallet, location]);




  useEffect(() => {
    const onResize = () => {
      setIsMobile(getIsMobile());
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      setCollapsed(false);
    }
  }, [isMobile]);

  return (
    <Layout className="relative min-h-screen overflow-hidden">
      <Sider
        className="bottom-[0px] left-[0px] right-[0px] !fixed"
        height={60}
        width={"100%"}
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        <Menu
          className="menu-main  flex"
          theme="dark"
          mode="inline"
          defaultSelectedKeys={["/"]}
          selectedKeys={[activeMenuKey]}
        >
          <Menu.Item key="/">
            <NavLink 
              to="/" 
              className="flex flex-col items-center gap-2"
            >
              <HomeOutlined />
              <span className="max-w-40 ml-0">Home</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/leaderboard">
            <NavLink 
              to="/leaderboard" 
              className="flex flex-col items-center gap-2"
            >
              <TrophyOutlined />

              <span className="max-w-40 ml-0">Leaderboard</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/friends">
            <NavLink 
              to="/friends" 
              className="flex flex-col items-center gap-2"
            >
              <UsergroupAddOutlined />

              <span className="max-w-40 ml-0">Friends</span>
            </NavLink>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        
        <Content >
          <div className="container mx-auto max-w-[600px]">
            {children}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
export default TemplateLayout;
