import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTonConnectUI, useTonAddress } from '@tonconnect/ui-react';

import rw1 from "../../assets/img/reward1.png";
import rw2 from "../../assets/img/reward2.png";
import rw3 from "../../assets/img/reward3.png";

import fox1 from "../../assets/img/fox1.png";

import { truncateString } from "../../ultils";

import axios from "axios";
import { FieldTimeOutlined, UsergroupAddOutlined, WalletOutlined } from '@ant-design/icons';

import { useInitData, useLaunchParams, type User } from '@telegram-apps/sdk-react';

import { TonConnectButton } from '@tonconnect/ui-react';

export default function Leaderboard() {

    const [tonConnect] = useTonConnectUI();
    const walletAddress = useTonAddress();
    const [userData, setUserData] = useState<any>();

    const [leaderboard, setLeaderboard] = useState<any>();

    const initData = useInitData();
    const tgUser = initData.user;

    useEffect(() => {
        fetchUser();
	}, [tgUser]);

    useEffect(() => {
        fetchLeaderboard();
	}, []);


    const fetchUser = async () => {
        const u = await getUser(tgUser.id);
        setUserData(u);
    };
    const fetchLeaderboard = async () => {
        const result = await axios.get(`${process.env.REACT_APP_API}/users/leaderboard`);
        setLeaderboard(result.data.data);
    };
    
    const getUser = async (tgId) => {
        const result = await axios.get(`${process.env.REACT_APP_API}/users/${tgId}`);
        return result.data.data
    }

    console.log(`leaderboard`, leaderboard)

    return(
        <>
            <div className="mt-10 px-3" >
                <div className="mt-4 text-[30px] font-bold text-[#ffffff]">
                    Telegram <br/>Wall of Frame
                </div>
            </div>
            <div className="mt-10 px-3" >
                <div className="bg-[#232323] p-2 px-3 rounded-xl mt-2 flex gap-3 items-center">
                    <div>
                        <img className="w-[50px] m-auto" src={fox1} />
                    </div>
                    <div className="flex-1">
                        <h3 className="text-lg font-bold text-white">@{tgUser.username}</h3>
                        <p>{tgUser.id}</p>
                    </div>
                    <h3 className="text-lg font-bold text-white">{userData?.total?.toLocaleString('en-US')} <span className="text-[#ed7b34]">FOXS</span></h3>
                </div>
            </div>

            {
                leaderboard && leaderboard.length > 0 &&
                    <div className="mt-10 px-3">
                        <h3 className="text-xl font-bold mb-4">{(30000+leaderboard.length).toLocaleString('en-US')} holders</h3>
                        {
                            leaderboard.slice(0, 20).map((item, index) => {
                                return (
                                    <div className="flex gap-3 items-center justify-between text-[16px] font-medium mb-5">
                                        {
                                            index == 0 ? ( <img className="w-[40px] m-auto" src={ rw1 } /> ) :
                                            index == 1 ? ( <img className="w-[40px] m-auto" src={ rw2 } /> ) :
                                            index == 2 ? ( <img className="w-[40px] m-auto" src={ rw3 } /> ) :
                                            <span className="w-[40px] h-[40px] m-auto flex items-center justify-center rounded-full bg-[#181818]">{index+1}</span>
                                        }
                                        <div className="content">{truncateString(item.tg_username, 5, 3)}</div>
                                        <div className="amount flex-1 text-right">{item.total.toLocaleString('en-US')} <span className="text-[#ed7b34]">FOXS</span></div>
                                    </div>
                                )
                            })
                        }
                    </div>
            }
            
            
        </>
    );
}