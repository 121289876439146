import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTonConnectUI, useTonAddress } from '@tonconnect/ui-react';

import { useInitData, useLaunchParams, type User } from '@telegram-apps/sdk-react';
import { initUtils } from '@telegram-apps/sdk';

import fox1 from "../../assets/img/fox1.png";
import { UsergroupAddOutlined } from '@ant-design/icons';

import { TonConnectButton } from '@tonconnect/ui-react';

import toast from "react-hot-toast";

export default function Friends() {

    const [tonConnect] = useTonConnectUI();
    const walletAddress = useTonAddress();

    const utils = initUtils();

    const initData = useInitData();
    console.log('initData', initData)
    const tgUser = initData.user;
    console.log('tgUser', tgUser)

    useEffect(() => {
        
	}, [])
    
    return(
        <>
            <div className="mt-10 text-center px-3" >
                <div className="mt-4 text-[30px] font-bold text-[#ffffff]">
                    Invite friends<br/>and get more <span className="text-[#ed7b34]">FOXS</span>
                </div>
                <img className="w-[140px] m-auto mt-7" src={fox1} />
            </div>
            <div className="mt-7 px-3">
                <div className="mt-4 text-[20px] text-center" >
                    Tap on the button<br />to invite your friends
                </div>

                <button
                    className="px-3 py-4 text-[17px] font-medium rounded-xl flex justify-center items-center gap-2 bg-[#fff] w-full mt-7"
                    onClick={() => {
                        navigator.clipboard.writeText(`https://t.me/foxshouse7_bot/foxshouse7?startapp=${tgUser?.id}`);
                        toast.success("Copied!");
                      }}
                  >
                    <span className="text-[#000]">Copy invite link</span>
                </button>
                <button
                    className="px-3 py-4 text-[17px] font-medium rounded-xl flex justify-center items-center gap-2 bg-[#fff] w-full mt-2"
                    onClick={ () => { utils.shareURL(`https://t.me/foxshouse7_bot/foxshouse7?startapp=${tgUser?.id}`, `Who let the FOXS out?`); } }
                  >
                    <span className="text-[#000]">Share invite link</span>
                </button>
            </div>
            
        </>
    );
}