import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTonConnectUI, useTonAddress } from '@tonconnect/ui-react';

import { useInitData, useLaunchParams, type User } from '@telegram-apps/sdk-react';
import { NavLink } from "react-router-dom";
import gold from "../../assets/img/gold.png";
import fox1 from "../../assets/img/fox1.png";

import axios from "axios";

export default function Score() {

    const [tonConnect] = useTonConnectUI();
    const walletAddress = useTonAddress();
    const [userData, setUserData] = useState<any>();
    
    const initData = useInitData();
    const tgUser = initData.user;

    useEffect(() => {
        fetchUser();
	}, [tgUser, walletAddress]);

    const fetchUser = async () => {
        const u = await getUser(tgUser.id);
        setUserData(u);
    };
    const getUser = async (tgId) => {
        const result = await axios.get(`${process.env.REACT_APP_API}/users/${tgId}`);
        return result.data.data
    }
    
    return(
        <>
            <div className="mt-3 text-center px-3" >
                <div className="mb-3 text-[24px] font-bold text-[#ffffff]">
                    Elite member!
                </div>
                <div className="text-[18px] font-bold">You've joined Telegram</div>
            </div>
            <div className="text-center px-3 relative" >
                <div className="mt-4 text-[160px] font-bold text-[#ffffff] text-center relative z-[9]">{userData?.age}</div>
                <img className="w-[260px] absolute top-0 right-0 left-0 m-auto opacity-50" src={gold} />
                <div className="mt-4 text-center text-[18px] font-bold relative z-[9]">year ago (*)</div>
            </div>
            <div className="mt-5 px-3">
                <div className="mt-4 text-center">Your FOXS reward</div>
                <div className="mt-4 text-[30px] text-center flex items-center justify-center gap-2 font-bold">
                    <img className="w-[25px] h-[25px]" src={fox1} />
                    {userData?.total.toLocaleString('en-US')}
                </div>
                <div className="mt-4 text-center">Your account number is #{tgUser.id}.</div>
                <div className="mt-2 text-center">Thanks for your time on Telegram 🤝</div>
                <div className="text-xs mt-5 text-center">* Age accuracy is not guaranteed and is for fun ^^</div>
                <NavLink to="/" className="px-3 py-4 text-[17px] font-medium rounded-xl flex justify-center items-center gap-2 bg-[#fff] w-full mt-7">
                    <span className="text-[#000]">Continue</span>
                </NavLink>
            </div>
        </>
    );
}