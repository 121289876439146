import React from "react";

import { TonConnectUIProvider } from '@tonconnect/ui-react';

import { SDKProvider } from '@telegram-apps/sdk-react';

import { Toaster } from "react-hot-toast";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./App.css";

import TemplateLayout from "./components/Layout";
import Home from "./page/Home";
import Friends from "./page/Friends";
import Leaderboard from "./page/Leaderboard";
import Score from "./page/Score";

function App() {
  return (
      <TonConnectUIProvider manifestUrl={process.env.REACT_APP_MANIFESTURL}>
        {/*Root component*/}
        <SDKProvider acceptCustomStyles debug>
          <Router>
            <TemplateLayout>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/leaderboard" element={<Leaderboard />} />
                <Route path="/friends" element={<Friends />} />
                <Route path="/score" element={<Score />} />
              </Routes>
            </TemplateLayout>
          </Router>
          <Toaster
            position="top-center"
            toastOptions={{
              duration: 2500,
            }}
          />
        </SDKProvider>
      </TonConnectUIProvider>
  );
}

export default App;
