import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTonConnectUI, useTonAddress } from '@tonconnect/ui-react';

import fox1 from "../../assets/img/fox1.png";
import { NavLink, useLocation } from "react-router-dom";
import { CheckCircleOutlined, UsergroupAddOutlined, WalletOutlined, CrownOutlined, HeartOutlined } from '@ant-design/icons';

import { TonConnectButton } from '@tonconnect/ui-react';

import { Modal } from "antd";

import axios from "axios";
import { useInitData, retrieveLaunchParams } from '@telegram-apps/sdk-react';

import { Api, TelegramClient } from "telegram";
import { StringSession } from "telegram/sessions";

import ReactLoading from "react-loading";

export default function Home() {

    const [tonConnect] = useTonConnectUI();
    const walletAddress = useTonAddress();

    const [userData, setUserData] = useState<any>();

    const { initDataRaw } = retrieveLaunchParams();

    const [isCheckingModalOpen, setCheckingModalOpen] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    

    const handleCancelCheckingModal = () => {
        setCheckingModalOpen(false);
    };

    // const session = new StringSession(""); // You should put your string session here
    // const client = new TelegramClient(session, 16891800, '7de967de648c027b3eaf30cb85bbe6bc', {});
    // const client = new TelegramClient(new StringSession(''), 16891800, '7de967de648c027b3eaf30cb85bbe6bc', {});

    const initData = useInitData();
    const tgUser = initData.user;
    // console.log('tgUser', tgUser)
    const ref_from = initData.startParam;
    // console.log('ref_from', ref_from);

    // const id = 740772076;

    useEffect(() => {
        fetchUser();
	}, [tgUser, walletAddress]);

    const setChecking = async () => {
        setCheckingModalOpen(true);
        const interval = setInterval(() => {
            setIsChecked(true);
        }, 10000);
        return () => clearInterval(interval);
    }

    const fetchUser = async () => {
        // await client.connect(); // This assumes you have already authenticated with .start()
        // const result: Api.users.UserFull = await client.invoke(
        //     new Api.users.GetFullUser({
        //       id: 'judasle7',
        //     })
        // );
        // console.log(result); // prints the result

        const u = await getUser(tgUser.id);
        
        if(u){
            setUserData(u);
            // check wallet
            if(walletAddress && u.is_connected_wallet == 0){
                const updated = await addPoint({tg_id: tgUser.id, amount: 1000, wallet: walletAddress, point_type: 'wallet'}); // connect wallet
                setUserData(updated);
            }
        }else{
            setChecking();
            const u2 = await addUser();
            if(u2){
                const u = await getUser(u2.tg_id);
                setUserData(u);
            }
        }
    };
    
    const getUser = async (tgId) => {
        const result = await axios.get(`${process.env.REACT_APP_API}/users/${tgId}`);
        return result.data.data
    }

    function randomIntFromInterval(min, max) { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min);
    }
    
    const addUser = async () => {
        // rand age first haha
        const age = randomIntFromInterval(2, 6);
        const amount = age*1000 + randomIntFromInterval(100, 900);
        const result = await axios.post(`${process.env.REACT_APP_API}/users/create`, {
                id: tgUser.id,
                tg_username: tgUser.username ? tgUser.username : tgUser.id,
                amount: amount, 
                ref_from: ref_from, 
                premium: tgUser.isPremium,
                age: age
            }, {
                headers: {
                    'Authorization': `tma ${initDataRaw}` 
                }
            }
        );
        return result.data.user
    };

    const addPoint = async (data) => {
        const result = await axios.post(`${process.env.REACT_APP_API}/users/point`, {
                id: data.tg_id,
                amount: data.amount, 
                wallet: data.wallet, 
                point_type: data.point_type
            }, {
                headers: {
                    'Authorization': `tma ${initDataRaw}` 
                }
            }
        );
        return result.data.user
    };
    
    return(
        <>
            <div className="text-center" >
                <NavLink to="/score" className={`px-3 py-5 text-[18px] font-medium flex justify-center items-center gap-2 w-full your-score`} >
                    <CrownOutlined/> <span>Your Score</span>
                </NavLink>
            </div>
            <div className="mt-7 text-center px-3" >
                <img className="w-[120px] m-auto" src={fox1} />

                <div className="mt-4 text-[34px] font-bold">
                    {userData?.total.toLocaleString('en-US')} <span className="text-[#ed7b34]">FOXS</span>
                </div>

                <div className="mt-7">
                    <TonConnectButton className="ton-connect-button" />
                </div>
            </div>
            <div className="mt-7 px-3">
                <div className="bg-[#232323] p-3 rounded-xl flex gap-2 items-start">
                    <div className="flex-1">
                        <h3 className="font-bold text-white">FOXS COMMUNITY</h3>
                        <p className="text-xs">Home for Telegram OGs</p>
                    </div>
                    <button className="bg-[#fff] font-medium py-2 px-5 rounded-lg text-[#000]">Join</button>
                </div>
                <div className="bg-[#232323] p-3 rounded-xl mt-2 flex gap-2 items-start">
                    <div className="flex-1">
                        <h3 className="font-bold text-white">FOLLOW US ON X.COM</h3>
                        <p className="text-xs">Stay updated with the latest news</p>
                    </div>
                    <button className="bg-[#fff] font-medium py-2 px-5 rounded-lg text-[#000]">Follow</button>
                </div>
            </div>
            <div className="mt-10 px-3">
                <h3 className="text-xl font-bold text-center mb-4">Your rewards</h3>

                {
                    userData && userData.points.length &&
                    userData.points.map((item) => {
                        return (
                            <div className="flex gap-3 items-center justify-between text-[16px] font-medium mb-5">
                                <div className="image rounded-full bg-[#232323] w-[40px] h-[40px] leading-[40px] text-center">
                                    { item.point_type == 'new' && <HeartOutlined /> }
                                    { item.point_type == 'premium' && <CrownOutlined /> }
                                    { item.point_type == 'invite' && <UsergroupAddOutlined /> }
                                    { item.point_type == 'wallet' && <WalletOutlined /> }
                                </div>
                                <div className="content">
                                    { item.point_type == 'new' && <span>Account age</span> }
                                    { item.point_type == 'premium' && <span>Telegram Premium</span> }
                                    { item.point_type == 'invite' && <span>Invited friends</span> }
                                    { item.point_type == 'wallet' && <span>Connect Wallet</span> }
                                </div>
                                <div className="amount flex-1 text-right">+{item.point.toLocaleString('en-US')} <span className="text-[#ed7b34]">FOXS</span></div>
                            </div>
                        )
                    })
                }
            </div>
            <Modal
                forceRender
                open={isCheckingModalOpen}
                onCancel={handleCancelCheckingModal}
                footer={false}
                className="modal-create-token"
            >
                <div className="text-center mt-6">
                    <div className="flex justify-center mb-3">
                        <img className="w-[30px]" src={fox1} />
                    </div>
                    <h3 className="font-medium text-[20px] text-white mb-10">Checking your account</h3>
                    <div className="checking mb-10">
                        {
                            !isChecked ? 
                                <div className="flex justify-center">
                                    <ReactLoading type="bars" height={40} width={40} color="#ffffff" />
                                </div>
                            : 
                            <>
                                <div className="flex justify-center mb-10">
                                    <CheckCircleOutlined />
                                </div>
                                <NavLink to="/score" className="px-3 py-4 text-[17px] font-medium rounded-xl flex justify-center items-center gap-2 bg-[#fff] w-full">
                                    <span className="text-[#000]">Continue</span>
                                </NavLink>
                            </>
                        }
                    </div>
                </div>
                
            </Modal>
        </>
    );
}